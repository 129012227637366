<script setup lang="ts">
useHead({
  bodyAttrs: {
    class: 'bg-green-500',
  },
});
</script>

<template>
  <div class="flex min-h-screen flex-col">
    <TnHeader />
    <div class="mx-auto max-w-7xl flex-1 px-0 py-12 sm:px-6 lg:px-8">
      <slot />
    </div>
    <TnFooter class="mt-auto" />
  </div>
</template>
